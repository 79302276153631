<template>
  <div class="home">
    <div class="header">
      <div class="header-left">
        <div class="img">
          <img class="img" :src="channelInfo.logo || 'https://tax-trade.oss-cn-chengdu.aliyuncs.com/template/zfy_logo.jpeg'" alt="" />
        </div>
        <div class="line"></div>
        <div class="qy-center">分时领英</div>
      </div>
      <div>
        <a-button
          type="primary"
          style="background: #6379f5; border: 0"
          class="header-right"
          @click="toLogin"
          >企业入口</a-button
        >
        <a-button
          type="primary"
          style="background: #07c160; border: 0; margin-left: 20px"
          class="header-right"
          @click="showWxCode = true"
          >工作者入口</a-button
        >
      </div>
    </div>
    <div class="wx-login" v-show="showWxCode">
      <div class="wx-login-container">
        <div class="close-arrow">
          <a-icon
            @click="closeWxCode"
            type="close"
            style="color: #ffffff; font-size: 18px; cursor: pointer"
          />
        </div>
        <div class="wx-login-content">
          <div class="wx-login-title">工作者入口</div>
          <!-- 二维码 -->
          <div class="wx-code">
            <img src="../../assets/workIn.jpg" alt="" />
          </div>
          <div class="wx-login-footer">
            请使用微信扫描二维码进入<br />“分时领英”
          </div>
        </div>
      </div>
    </div>
    <div class="content-one">
      <div class="descript">
        最便捷的雇佣方案 <br />提升企业工作效率，引领企业数字化转型
      </div>
      <!-- <a-button class="btn" @click="toLogin">立即登录</a-button> -->
    </div>
    <div class="content-two">
      <div class="content-twol">
        <div class="ctwo-title">重新定义工作</div>
        <div class="ctwo-desc">
          工作的目的不是完成任务，而是人与人之间的协作与创造。
          用全新的工作方式和工具去更好的协作并且创造更大价值，
          这是我们对未来工作的向往
        </div>
        <div class="circle"></div>
      </div>
      <div class="content-twor"></div>
    </div>
    <!-- <div class="content-three">
      <div class="cthree-content">
        <div class="cthree-ctittle"><span class="line"></span> 覆盖城市</div>
        <div class="cthree-count">13</div>
      </div>
      <div class="cthree-content">
        <div class="cthree-ctittle">
          <span class="line"></span> 企业发布需求
        </div>
        <div class="cthree-count">648</div>
      </div>
      <div class="cthree-content">
        <div class="cthree-ctittle"><span class="line"></span> 自由工作者</div>
        <div class="cthree-count">1222</div>
      </div>
      <div class="cthree-content">
        <div class="cthree-ctittle"><span class="line"></span> 履约支付</div>
        <div class="cthree-count">66283023</div>
      </div>
    </div> -->
    <div class="content-four">
      <div class="content-fourl">
        <div class="cfour-title">重新定义生活</div>
        <div class="cfour-desc">
          过往的生活被工作所制约，未来的生活由你来定义。
          打破了时间和地理的限制，让我们以不同角色 尽情地热爱生活。
        </div>
        <div class="circle"></div>
      </div>
      <div class="content-fourr"></div>
    </div>
    <div class="content-five">
      <div class="cf-title">我们的团队</div>
      <div class="cf-desc">
        在分时领英工作是一种什么样的体验？将日常工作场景<br />放到你眼前，带你一起感受我们的公司。<br /><span
          >#使命感 #正能量 #无限可能</span
        >
      </div>
      <a-button class="join-us" @click="toLogin">加入我们</a-button>
    </div>
    <div class="footer">
      <!-- <div class="footer-top">
        <div class="footer-tl">
          <ul>
            <li>关于</li>
            <li @click="toLogin">加入我们</li>
          </ul>
          <ul>
            <li>协议与规则</li>
            <li>法律声明及隐私权政策</li>
            <li>用户协议</li>
          </ul>
          <ul>
            <li>联系我们</li>
            <li>邮箱：service@zfycloud.com</li>
            <li>网址：www.zfycloud.com</li>
            <li>电话：400-996-2055</li>
          </ul>
        </div>
        <div class="footer-tr">
          <div class="ft-common">
            <img src="../../assets/ft_04.png" alt="">
            <div class="ft-common-name">分时领英</div>
          </div>
          <div class="ft-common">
            <img src="../../assets/erweima_01.jpg" alt="">
            <div class="ft-common-name">智赋云公众号</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="footer-bottom">四川省智赋云科技有限公司 ©2020 备案号：<a href="https://beian.miit.gov.cn" target="blink">蜀ICP备案20007130号</a></div> -->
      <div class="footer-bottom">{{channelInfo.copyRight}}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { getEnterpriseLogo } from "@/api/api";
export default {
  name: "Home",
  data() {
    return {
      showWxCode: false,
      channelInfo: {},
    };
  },
  created() {
    // 全局提示
    let getTip = window.localStorage.getItem("allTipsFlag");
    if (getTip) {
    } else {
      window.localStorage.setItem(
        "allTipsFlag",
        JSON.stringify({
          addTaskTips: 0,
          invite_danci: 0,
          invite_duoci: 0,
          drawer_danci: 0,
          drawer_duoci: 0,
          settlement_danci: 0,
          settlement_duoci: 0,
        })
      );
    }

    const token = Vue.ls.get(ACCESS_TOKEN);

    if (token && token.length > 0) {
      this.$router.push("/rask");
      return;
    }

    this.getEnterpriseLogoInfo();
  },
  methods: {
    getEnterpriseLogoInfo() {
      let enterpriseId = this.getUrlSearch("cl") || 0;
      // getEnterpriseLogo(enterpriseId).then((res) => {
      //   if (res.success && res.result) {
      //     this.channelInfo = res.result;
      //     window.localStorage.setItem('enterpriseLogoInfo',JSON.stringify(this.channelInfo))
      //   } else {
      //   }
      // });
    },
    getUrlSearch(name) {
      // 未传参，返回空
      if (!name) return null;
      // 查询参数：先通过search取值，如果取不到就通过hash来取
      var after = window.location.search;
      after = after.substr(1) || window.location.hash.split("?")[1];
      // 地址栏URL没有查询参数，返回空
      if (!after) return null;
      // 如果查询参数中没有"name"，返回空
      if (after.indexOf(name) === -1) return null;

      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      // 当地址栏参数存在中文时，需要解码，不然会乱码
      var r = decodeURI(after).match(reg);
      // 如果url中"name"没有值，返回空
      if (!r) return null;

      return r[2];
    },

    parseQueryString() {
      var str = window.location.search;
      // var str = "http://zfycloud.mynatapp.cc/?code=041qyJFa1tA52A0sBQIa1G9Ufa0qyJFD&state=#/";
      var objURL = {};

      str.replace(
        new RegExp("([^?=&]+)(=([^&]*))?", "g"),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
        }
      );
      return objURL;
    },
    toLogin() {
      this.$router.push("/login");
    },
    closeWxCode() {
      this.showWxCode = false;
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  .header-left {
    display: flex;
    align-items: center;
    .img {
      // width: 30px;
      height: 36px;
      margin-right: 7px;
    }
    .name {
      font-size: 21px;
      font-family: "FZZhengHeiS-M-GB";
      color: #202020;
    }
    .eg-name {
      font-size: 8px;
      font-family: "FZZhengHeiS-M-GB";
      font-weight: 400;
      color: #202020;
      letter-spacing: 2px;
    }
    .line {
      width: 1px;
      height: 30px;
      background: #ababab;
      margin: 0 5px;
    }
    .qy-center {
      font-size: 16px;
      color: #202020;
    }
  }
  .haeder-right {
    width: 100px;
    height: 36px;
    border-radius: 2px;
    color: #ffffff;
    font-size: 14px;
  }
}
.content-one {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(../../assets/home.png) no-repeat center;
  background-size: cover;
  .descript {
    width: 1080px;
    font-size: 60px;
    color: #ffffff;
    text-align: center;
    line-height: 100px;
  }
  .btn {
    margin-top: 55px;
    width: 193px;
    height: 54px;
    border: 2px solid #ffffff;
    border-radius: 27px;
    font-size: 20px;
    color: #ffffff;
    background: rgba(0, 0, 0, 0);
  }
}
.content-two {
  height: 680px;
  width: 100%;
  position: relative;
  .content-twol {
    background: #ffffff;
    width: 50%;
    height: 680px;
    padding: 181px 0 0 155px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: skew(-23deg, 0deg);
    .ctwo-title {
      width: 300px;
      font-size: 46px;
      color: #202020;
      margin-bottom: 40px;
      transform: skew(23deg, 0deg);
      margin-left: -48px;
    }
    .ctwo-desc {
      width: 457px;
      text-align: justify;
      font-size: 18px;
      color: #202020;
      transform: skew(23deg, 0deg);
    }
    .circle {
      position: absolute;
      top: 132px;
      left: 34px;
      width: 108px;
      height: 108px;
      background: #e9f2e9;
      border-radius: 50%;
      z-index: -1;
      transform: skew(23deg, 0deg);
    }
  }
  .content-twor {
    position: absolute;
    top: 0;
    right: 0;
    width: 70%;
    height: 680px;
    z-index: -1;
    background: url(../../assets/ct_02.jpg) no-repeat;
    background-size: cover;
  }
}
.content-three {
  width: 100%;
  height: 280px;
  background: #2b3341;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 160px;
  .cthree-content {
    .cthree-ctittle {
      display: flex;
      align-items: center;
      color: #ffffff;
      font-size: 16px;
      .line {
        width: 3px;
        height: 16px;
        background: #ffffff;
        margin-right: 4px;
      }
    }
    .cthree-count {
      font-size: 60px;
      color: #ffffff;
    }
  }
}
.content-four {
  height: 680px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .content-fourl {
    background: #ffffff;
    width: 50%;
    height: 680px;
    padding: 181px 155px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: skew(23deg, 0deg);
    .cfour-title {
      width: 300px;
      font-size: 46px;
      color: #202020;
      margin-bottom: 40px;
      transform: skew(-23deg, 0deg);
      margin-left: 300px;
    }
    .cfour-desc {
      width: 457px;
      text-align: justify;
      font-size: 18px;
      color: #202020;
      transform: skew(-23deg, 0deg);
      margin-left: 250px;
    }
    .circle {
      position: absolute;
      top: 132px;
      left: 540px;
      width: 108px;
      height: 108px;
      background: #ffefe0;
      border-radius: 50%;
      z-index: -1;
      transform: skew(-23deg, 0deg);
    }
  }
  .content-fourr {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 680px;
    z-index: -1;
    background: url(../../assets/ct_03.jpg) no-repeat;
    background-size: cover;
  }
}
.content-five {
  height: 480px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .cf-title {
    font-size: 46px;
    color: #202020;
    margin-bottom: 40px;
  }
  .cf-desc {
    font-size: 18px;
    line-height: 32px;
    color: #202020;
    margin-bottom: 33px;
    text-align: center;
    span {
      color: #3c58f2;
    }
  }
  .join-us {
    width: 160px;
    height: 48px;
    background: #3c58f2;
    border-radius: 24px;
    border: 0;
    font-size: 18px;
    color: #ffffff;
  }
}
.footer {
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 1px 290px 20px;
  .footer-top {
    display: flex;
    justify-content: space-between;
    .footer-tl {
      width: 700px;
      display: flex;
      justify-content: space-between;
      ul {
        padding: 0;
        list-style: none;
        li {
          font-size: 16px;
          color: #8b93a7;
          line-height: 30px;
          &:first-child {
            font-size: 20px;
            color: #ffffff;
            margin-bottom: 10px;
          }
          &:not(:first-child) {
            cursor: pointer;
          }
        }
      }
    }
    .footer-tr {
      display: flex;
      .ft-common {
        margin-right: 40px;
        &:last-child {
          margin: 0;
        }
        img {
          width: 128px;
          height: 128px;
        }
        .ft-common-name {
          margin-top: 13px;
          color: #8b93a7;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 25px;
    a {
      color: #999999;
      &:hover {
        color: #3c58f2;
      }
    }
  }
}
.wx-login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  .wx-login-container {
    width: 729px;
    height: 500px;
    .close-arrow {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 20px;
    }
    .wx-login-content {
      width: 400px;
      height: 400px;
      // background: #FFFFFF;
      margin: 0 auto;
      position: relative;
      .wx-login-title {
        text-align: center;
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .wx-code {
        width: 290px;
        height: 290px;
        background: #ffffff;
        padding: 20px;
        margin: 0 auto;
      }
      .wx-login-footer {
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        margin-top: 20px;
      }
      .wx-login-reget {
        position: absolute;
        top: 0;
        width: 400px;
        height: 430px;
        background: rgba(255, 255, 255, 0.9);
        z-index: 60;
        display: flex;
        justify-content: center;
        align-items: center;
        .wx-login-getbtn {
          background: #3c58f2;
          width: 200px;
          height: 40px;
          font-size: 14px;
          padding: 10px;
        }
      }
    }
  }
}
</style>
